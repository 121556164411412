.unauthorized,
.authorized,
.order-accepted {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 56px 0 72px;
  overflow-y: auto;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex: 1 0 auto;
    padding: 16px;

    &-sticker {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 16px 0;
      img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 140px;
        height: 140px;
      }
    }

    &-title {
      color: $c-black;
      text-align: center;
      font-size: 18px;
      font-weight: 700;
      line-height: 20px;
    }

    &-text {
      color: #4d4d4d;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
    }

    &-number {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;

      color: #4d4d4d;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      span {
        color: $c-black;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
    width: 100%;
  }
}

.checkout {
  .order-accepted__content-title {
    margin-bottom: 15px;
  }
}
