.devices-container {
  //background-color: red;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 0 9px 9px;
  width: 100%;
  border-radius: 5px;

  .devices-text {
    display: flex;
    align-items: center;

    .icon {
      font-size: 28px;
    }

    .label {
      margin-left: 8px;
      //margin-right: 30px;
      font-size: 16px;
    }
  }

  .toggle-block {
    //background-color: green;
    min-width: 52px;
    max-width: 52px;

    .toggle-button {
      display: inline-block;
      width: 52px;
      height: 30px;
      position: relative;
      border-radius: 30px;
      background-color: #e0e0e0;
      cursor: pointer;
      transition: background-color 0.3s ease-in-out;

      &:before {
        content: '';
        position: absolute;
        top: 3px;
        left: 3px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: #fff;
        transition: transform 0.3s ease-in-out;
      }

      &.active {
        background-color: var(--green-green);

        &:before {
          transform: translateX(20px);
        }
      }
    }
  }

  .counter {
    display: flex;
    width: 100%;
    max-width: 144px;
    margin: 0 8px;
    height: 28px;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    border: 1px solid var(--black-to-white-white-90-white-50-disabled, #e6e6e6);
    background: var(--black-to-white-white-100, #fff);

    .count {
      margin: 0 44px;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }

    .decrement,
    .increment {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}
