@media screen and (max-width: 768px) {
  h1 {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
  }
  h2 {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }
  h3 {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }
  h4 {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }
  h5 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 114.286% */
  }
  p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  small {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
  }
}
