.profile-desc {
  display: flex;
  padding: 30px 0;

  .profile-desc-left {
    width: 40%;
    margin-right: 20px;
  }
  .profile-desc-right {
    width: 60%;
    height: 100%;
    .orders__list {
      padding: 0;
    }
    .profile-desc_box {
      height: 100%;
      .btn {
        width: 100%;
      }
    }
  }

  .profile-desc_box {
    margin: 15px 0;
    padding: 15px;
    background-color: #fff;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.12);
    border-radius: 17px;

    .profile-desc_box-title {
      display: flex;
      margin-bottom: 15px;
      .box-title_img {
        margin-right: 15px;
      }
      .box-title_title {
        color: #111;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
      }
    }

    .profile-desc_box_imgbutton {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 44px;
      width: 100%;
      padding: 4px 16px;
      border-radius: 12px;
      background: #e6e6e6;
      margin-top: 10px;
      border: none;
      color: #8d191d;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      cursor: pointer;
      text-decoration: none;
      svg {
        margin-right: 10px;
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }

  .personal-box {
    margin-top: 25px;
    .personal-box_title {
      color: var(--Red-Red, #8d191d);
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      margin-bottom: 10px;
      a {
        color: var(--Red-Red, #8d191d);
        text-decoration: none;
      }
    }
    .personal-box_phone {
      color: #4d4d4d;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
    }
  }
}

//авториз
.auth-content__info-title {
  font-size: 24px;
  font-weight: 700;
}
.auth-button {
  width: 250px !important;
}

.login-container_modal {
  background-color: #fff;
  border-radius: 15px;

  padding: 0;
  overflow: hidden;
  height: 480px;
  .page-contaiter {
    padding: 0;
  }
}
