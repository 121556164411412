.input__item {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  &.input_icon {
    position: relative;
    img,
    svg {
      &:first-child {
        position: absolute;
        left: 16px;
      }
      &:last-child {
        position: absolute;
        right: 16px;
      }
    }

    &_left {
      .input__container {
        input {
          padding-left: 44px;
        }
      }
    }

    &_right {
      .input__container {
        input {
          padding-right: 44px;
        }
      }
    }
  }

  .input_address {
    width: 66%;
  }
  .input_house {
    width: 32%;
  }

  .adress_fild {
    display: flex;
    justify-content: space-between;
  }

  label {
    color: $c-black;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }

  .input__container {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;

    input {
      width: 100%;
      display: flex;
      padding: 8px 16px;
      align-items: center;
      gap: 4px;
      align-self: stretch;

      border: none;
      outline: none;
      border-radius: 14px;
      background: var(--black-to-white-white-95, #f2f2f2);

      color: $c-black;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;

      &::placeholder {
        font-family: 'Tilda Sans', serif;
        color: $c-gray;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
      &[placeholder] {
        text-overflow: ellipsis;
      }
      &[readonly] {
        cursor: pointer;
      }
      &[type='date'] {
        cursor: pointer;
      }
      &[type='time'] {
        cursor: pointer;
      }
    }

    .form__textarea {
      width: 100%;
      height: 60px;
      display: flex;
      padding: 8px 16px;
      align-items: center;
      gap: 4px;
      align-self: stretch;

      border: none;
      outline: none;
      border-radius: 14px;
      background: var(--black-to-white-white-95, #f2f2f2);

      color: $c-black;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;

      &::placeholder {
        font-family: 'Tilda Sans', serif;
        color: $c-gray;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
      &[placeholder] {
        text-overflow: ellipsis;
      }
      &[readonly] {
        cursor: pointer;
      }
      &[type='date'] {
        cursor: pointer;
      }
      &[type='time'] {
        cursor: pointer;
      }
    }

    img {
      width: 24px;
      height: 24px;
    }
  }
}

.input__checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;

  .checkbox {
    position: relative;
    display: flex;
    padding: 8px;
    align-items: flex-start;
    gap: 4px;
    input {
      -webkit-appearance: none;
      appearance: none;
      font: inherit;
      color: currentColor;
      width: 18px;
      margin: 0;
      height: 18px;
      border: 2px solid #b3b3b3;
      border-radius: 0.15em;
      transform: translateY(-0.075em);

      &:checked {
        border: 0;
        background-color: $c_red;
        & + svg {
          display: block;
        }
      }
      &[type='checkbox'] {
        cursor: pointer;
      }
    }

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -60%);
      display: none;
    }
  }

  &-label {
    display: flex;
    align-items: center;
    gap: 4px;
    span {
      color: $c-gray;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 125% */
    }
  }
}

.input-h {
  height: 28px;
}

.input-fix {
  min-height: 28px;

  input[type='number'] {
    height: 150%;
  }
}

.input__counter {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: 1px solid #e6e6e6;

  &-sm {
    padding: 7px;
  }

  svg {
    flex-shrink: 0;
  }

  input {
    font-family: 'Tilda Sans', sans-serif;
    display: flex;
    max-width: 144px;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    outline: none;
    border: 0;
    height: 100%;
    padding: 0;
    width: 100%;

    color: $c-black;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
  }
}

.form-radio {
  display: flex;
  flex-direction: column;
  gap: 8px;

  label {
    color: $c-black;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }

  &__toggle {
    display: flex;
    padding: 2px;
    justify-content: center;
    align-items: center;
    border-radius: 14px;
    background: #f2f2f2;

    &__item {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px 7px;
      width: 40px;
      height: 40px;
      cursor: pointer;

      &-icon {
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 24px;
          height: 24px;
          fill: #999999;
        }
      }

      input[type='radio'] {
        display: none;
      }

      input[type='radio']:checked + div {
        background: #fff;
        box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.12);
        padding: 7px;
        border-radius: 12px;

        svg {
          fill: #8d191d;
        }
      }
    }
  }
}

.input__switch {
  display: flex;
  padding: 2px;
  justify-content: center;
  align-items: center;
  position: relative;

  width: 56px;
  height: 32px;

  &-icon {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(-50%);
    transition:
      transform ease 0.3s,
      background-color ease 0.3s;
    width: 24px;
    height: 24px;
    background-color: #f2f2f2;
    border-radius: 50%;
  }
  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    background: #ccc;
    transition: background-color ease 0.3s;
    z-index: -1;
  }

  input[type='checkbox'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    appearance: none;
    background: none;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    z-index: 2;
    cursor: pointer;
  }

  input[type='checkbox'] {
    &:checked {
      + .input__switch-icon {
        background: #fff;
        transform: translateX(50%);
      }
      ~ .input__switch-bg {
        background: $c-red;
      }
    }
  }
}

.form__field-wrapper__error {
  font-size: 11px;
  color: #8d191d;
  padding-left: 5px;
}
