.deliverymap-desc-modal {
  max-width: 780px;
  border-radius: 17px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 15px;
}
.deliverymap-desc {
  width: 100%;
  height: 100%;
  background-color: #fff;
  .map__topbar {
    width: 100%;
    box-shadow: none;
    max-width: 100%;
    position: relative;
  }
  .map__map {
    width: 100%;
    max-height: 370px;
  }
  .modal__wrapper {
    position: absolute;
    bottom: 0;
    z-index: 99;
    width: 100%;
    height: 205px;
    overflow: hidden;
  }
  .delivery-addresses {
    background-color: #fff;
    padding: 10px;
    height: 100%;

    overflow: auto;
    .top-bar {
      position: relative;
      box-shadow: none;
    }
  }
}
