.order-placement-remained {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 0;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 auto;
    gap: 16px;
    padding: 16px;

    &__info {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;

      &-title {
        text-align: center;
      }

      &-addition {
        color: #4d4d4d;
        text-align: center;
      }
    }

    &__list,
    .order-placement__check__info__list {
      display: flex;
      flex-direction: column;
      gap: 4px;
      padding: 16px;
      width: 100%;

      border-radius: 17px;
      background: #fff;
      box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.12);

      &__item,
      .order-placement__check__info__list__item {
        display: flex;
        flex-direction: column;

        &__info,
        .order-placement__check__info__list__item__info {
          display: flex;
          justify-content: space-between;
          align-items: center;

          &-title,
          .order-placement__check__info__list__item__info-title {
            max-width: 180px;
            overflow: hidden;
            color: $c-black;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          &-cost,
          .order-placement__check__info__list__item__info-cost {
            span {
              font-size: 12px;
              font-weight: 500;
              line-height: 16px;
            }
          }
        }

        &-price,
        .order-placement__check__info__list__item-price {
          color: #808080;
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
        }
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 8px;
  }
}

.order-placement {
  &__tabs {
    display: flex;
    flex-direction: column;
    padding: 16px 0 8px;
    gap: 8px;

    .form-radio {
      label {
        color: $c-black;
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;
      }

      &__toggle {
        padding: unset;

        &__item {
          width: unset;
          flex: 1 1 0;
          padding: 2px;
          align-items: unset;

          &-tab {
            display: flex;
            align-items: center;
            justify-content: center;

            color: #999999;
            font-family:
              Tilda Sans,
              sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
          }

          input[type='radio']:checked + div {
            color: $c_red;
            font-weight: 700;
            flex: 1 1 0;
          }
        }
      }
    }

    &__notification {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 10px;
      text-align: center;
      border-radius: 17px;
      border: 1px solid #8d191d;
      background: #fff;
      box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.12);
      font-size: 12px;

      h3,
      h4 {
        color: $c-black;
        font-size: 14px;
      }
      h5 {
        color: $c_red;
        font-size: 16px;
      }
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    padding: 8px;
    gap: 16px;
  }

  &__payment-method {
    display: flex;
    flex-direction: column;
    padding: 8px 0;

    &__item {
      padding: 10px 0;
    }

    &-title {
      display: flex;
      padding: 8px 0;
      font-size: 21px;
      margin-bottom: 10px;
      font-weight: 600;
    }
    &-box {
      margin-bottom: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      svg {
        path {
          fill: #111111;
        }
      }
    }
    &__item {
      &-name {
        color: var(--black-to-white-black);
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        flex: 1 1 0;
      }

      svg {
        width: 24px;
        height: 24px;
      }

      &-btn {
        display: flex;
        padding: 8px;

        svg {
          width: 24px;
          height: 24px;
        }

        input[type='radio'] {
          display: none;
        }

        input[type='radio']:checked + svg {
          path {
            d: path(
              'M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20ZM12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z'
            );
            fill: #8d191d;
          }
        }
      }
    }

    &__item.active {
      .order-placement__payment-method__item-name {
        color: #558950 !important;
      }
      svg {
        path {
          fill: #558950;
        }
      }
    }

    .input__item {
      .input__container {
        position: relative;
        a.nomoney {
          position: absolute;
          top: 6px;
          right: 5px;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }
  }

  &__check {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 8px;

    &__info {
      display: flex;
      flex-direction: column;
      padding: 16px;

      border-radius: 17px;
      background: #fff;
      box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.12);

      &-title {
        padding: 8px 0;
        font-size: 21px;
        font-weight: 600;
      }
      &__list {
        border-radius: unset;
        padding: 8px 0;
        box-shadow: unset;
      }
      &__price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 0;
      }
      &__delivery {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 8px 0;
        &__price {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        &__info {
          display: flex;
          flex-direction: column;
          gap: 4px;
          &-time {
            color: $c-black;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
          }
          &-contact {
            color: $c-black;
            font-size: 14px;
            font-weight: 700;
            line-height: 16px;
          }
          &-addresses {
            color: $c-black;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
          }
          &-additional {
            display: flex;
            align-items: center;
            gap: 4px;
            flex-wrap: wrap;
            color: #558950;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            span {
              color: #558950;
              font-size: 14px;
              font-weight: 700;
              line-height: 16px;
            }
            svg {
              width: 16px;
              height: 16px;
            }
          }
        }
      }

      &__total {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 0;
        &-cost {
          span {
            color: $c-black;
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }

      &__payment {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 16px 0;
        &__info {
          display: flex;
          flex-direction: column;
          gap: 4px;
          &__item {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &-name {
              color: $c-black;
              font-size: 16px;
              font-weight: 400;
              line-height: 20px;
            }
            &-cost {
              color: $c_red;
              font-size: 14px;
              font-weight: 700;
              line-height: 16px;
            }
          }
        }
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px;
    &-link {
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      padding: 6px 0;

      color: #808080;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;

      div {
        span {
          color: #8d191d;
        }
      }
    }
  }
}

.close-soon {
  opacity: 0.4;
}

.modal-order-placement {
  h3 {
    color: $c-black;
  }
  h4 {
    color: $c_red;
  }
  small {
    color: #4d4d4d;
  }

  &__content {
    padding: 8px 16px 16px;

    &__radio-item {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;

      &-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px;
        svg {
          width: 24px;
          height: 24px;
        }

        input[type='radio'] {
          display: none;
        }

        input[type='radio']:checked + svg {
          path {
            d: path(
              'M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20ZM12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z'
            );
            fill: #8d191d;
          }
        }
      }
      &-addresses {
        color: $c-black;
        font-size: 18px;
        font-weight: 600;
        line-height: 20px;
        flex: 1 1 0;
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4px;
        width: 44px;
        height: 44px;
        svg {
          width: 24px;
          height: 24px;
        }
      }
    }

    &-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 36px;
      padding: 4px;
    }
  }
}

.onspot_tabs {
  width: 100%;
  .onspot_tabsbox {
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    border-radius: 14px;
    background: var(--black-to-white-white-95, #f2f2f2);
  }
  .onspot_tabs-item {
    cursor: pointer;

    img {
      position: relative !important;
      border-radius: 15px;
    }
  }
  .onspot_tabs-item.selected {
    img {
      border: 1px solid #40633d;
      -webkit-box-shadow: 5px 5px 14px 1px rgba(194, 194, 194, 1);
      -moz-box-shadow: 5px 5px 14px 1px rgba(194, 194, 194, 1);
      box-shadow: 5px 5px 14px 1px rgba(194, 194, 194, 1);
    }
  }
}
.onspot_select_def {
  width: 100%;
  padding: 11px 0;
  background-color: #efefef;
  margin-bottom: 10px;
  margin-top: 20px;
  color: #333333;
  font-family: 'sf-ui-regular';
  font-size: 15px;
  border-radius: 100px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.onspot_select {
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 75px;
  z-index: 99;
  width: 100%;
  padding: 15px 20px;
  border-radius: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  .onspot_select_item {
    margin-top: 7px;
    color: #ababab;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
  }
  .onspot_select_item.active {
    color: #333333;
  }
  .onspot_select_item--title {
    color: #333333;
    font-weight: 500;
    margin-bottom: 5px;
  }
}

.onlipickup {
  h5 {
    color: #808080;
  }
  small {
    font-size: 18px;
    color: #558950;
    font-weight: 500;
  }
}

.order-placement__check__info__delivery__price-cost {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 18px;
  line-height: 111%;
  color: var(--black-to-white-black);
}
