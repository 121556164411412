.filter {
  display: flex;
  height: 28px;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 24px;
  border: 1px solid #bfbfbf;
  background: #fff;
  outline: none;
  font-family: 'Tilda Sans';
  color: #4d4d4d;
  font-weight: 400;
  font-size: 14px;
  font-style: normal;

  line-height: 20px; /* 142.857% */
  &.active {
    border: 2px solid #558950;
  }
  &[disabled] {
    opacity: 0.5;
  }
}
