.auth {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 0 0 56px 0;

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 150px;

    &__info {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      padding: 16px;

      &-title {
        color: $c-black;
        text-align: center;
      }

      &-text {
        color: #4d4d4d;
        text-align: center;
      }

      &-numb {
        color: #4d4d4d;
        text-align: center;
      }
    }

    &__inputs {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 16px;
      gap: 16px;

      &-sms {
        display: flex;
        justify-content: space-between;
        gap: 8px;

        &.validate--error {
          input {
            border: 1px solid #8d191d !important;
            background: #fae1e2 !important;
          }
        }

        input {
          width: 44px !important;
          height: 44px !important;
          border-radius: 14px !important;
          background: #f2f2f2 !important;
          border: none !important;
          margin: 0 5px !important;
          color: $c-black !important;
          font-family:
            Tilda Sans,
            sans-serif !important;
          font-size: 24px !important;
          font-style: normal !important;
          font-weight: 700 !important;
          line-height: 28px !important;

          &::placeholder {
            color: $c-gray !important;
          }
        }
      }
    }
  }

  .btn_sucsess-code {
    background-color: #558950;
    color: #fff;

    cursor: pointer;
  }
  &-footer {
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 8px;
    position: absolute;
    bottom: 60px;
    width: 100%;
    max-width: 760px;

    &__btn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px;
      height: 44px;
      border: none;
      border-radius: 12px;
      background: #8d191d;

      color: #fff;
      font-family:
        Tilda Sans,
        sans-serif;
      font-size: 18px;
      font-weight: 600;
      line-height: 20px;
    }

    &__link {
      padding: 6px 0;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      text-decoration: none;

      color: #808080;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }

    &__link-change {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px;
      text-decoration: none;
      height: 44px;
      border: none;
      background: none;

      font-family:
        Tilda Sans,
        sans-serif;
      color: $c_red;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
  }
}
.codewaith {
  font-size: 11px;
  color: #8d191d;
}

.login-error {
  font-size: 14px;
  font-weight: 500;
  color: #8d191d;
}

.labyrinth {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.labyrinth_item {
  margin: 5px;
  text-align: center;
}
