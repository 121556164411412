.select {
  &__search {
    position: sticky;
    top: 0;
    left: 0;
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    background: #fff;
    margin-bottom: 15px;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;

    img {
      height: 72px;
    }
  }
  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    padding: 8px 16px;

    color: $c_red;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }
  &__list {
    //width: 100%;
    display: flex;
    padding: 0 16px;
    align-items: flex-start;
    align-content: flex-start;
    gap: 8px;
    align-self: stretch;
    flex-wrap: wrap;
  }
  &__item {
    width: 100%;
    display: flex;

    gap: 16px;
    cursor: pointer;
    //margin-bottom: 10px;
    //&-box{
    //  width: 100%;
    //  display: flex;
    //  flex-wrap: wrap;
    //  justify-content: space-between;
    //}
    margin-bottom: 10px;
    &-box {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .item {
      &__letter {
        width: 40px;
        height: 40px;

        color: $c_red;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        flex-shrink: 0;
      }
      &__city {
        //background-color: red;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-right: 20px;
        gap: 8px;
        color: $c-black;
        font-size: 16px;
        padding: 10px 0;
        padding-right: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;

        section {
          display: flex;
        }
        a {
          text-decoration: none;
          color: #111111;
        }
        .city {
          &__institutes {
            color: $c-gray;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            margin-left: 10px;
          }
        }
      }
      &__check {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
      }
    }
  }
  &__button {
    &-wrapper {
      position: fixed;
      bottom: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      width: 100%;
      padding: 16px;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        #fff 21.29%
      );
      button {
        width: 100%;
      }
    }
  }
}
@media screen and (min-width: 560px) {
  .select__item .item__city {
    width: 50%;
  }
}
//@media screen and (max-width:400px) {
//  .select__item .item__city{
//    width: 70%;
//  }
//}
