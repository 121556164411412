.validate {
  display: flex;
  align-items: center;
  gap: 4px;

  font-size: 12px;
  font-weight: 400;
  line-height: 16px;

  &-error {
    color: $c_red;

    img {
      width: 16px;
      height: 16px;
    }
  }

  &-additional {
    color: #4d4d4d;
    text-decoration: underline;

    img {
      width: 16px;
      height: 16px;
    }
  }

  svg {
    width: 16px;
    height: 16px;
  }
}
