.recvisites_box {
  .word-bold {
    font-weight: 700;
    font-size: 17px;
    line-height: 120%;
    color: #000;
  }

  .ur-address {
    font-weight: 700;
    font-size: 11px;
    line-height: 120%;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #ccc;
    margin-bottom: 10px;
  }

  div {
    width: 70%;
    background: #fff;
  }
}
