.hidden_user {
	background: var(--red-red-click);
	width: 100%;
	height: 48px;
	display: flex;
	justify-content: center;

	.hidden_user_box {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.hidden_user_box-title {
			display: block;
			font-weight: 700;
			font-size: 18px;
			line-height: 111%;
			color: var(--black-to-white-white-100);
			margin: 0 20px;
			text-decoration: none;

			&:hover {
				text-decoration: none;
			}
		}

		.hidden_user_box-btn {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 180px;
			height: 36px;
			line-height: 36px;
			border-radius: 15px;
			text-align: center;
			background: var(--yellow-yellow);

			font-weight: 600;
			font-size: 16px;
			line-height: 125%;
			color: var(--black-to-white-black);

			span {
				margin-right: 10px;
			}
		}
	}
}

.footer-desc {
	background: var(--Deep-Blue, #132A3E);
	padding: 35px;
	color: #fff;

	.footer-desc_box {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.foot_menu {
		display: flex;
		flex-direction: column;
		align-self: flex-start;

		.foot_menu-title {
			font-size: 18px;
			font-style: normal;
			font-weight: 700;
			line-height: 20px;
			margin-bottom: 8px;
		}

		.foot_menu-item {
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px;
			margin-bottom: 8px;
			color: #fff;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	.foot_social-phone {
		font-size: 24px;
		font-style: normal;
		font-weight: 700;
		line-height: 28px;
	}

	.foot_social-social {
		margin-top: 15px;
		text-align: center;

		a {
			display: inline-block;
			margin-right: 10px;
		}
	}

	.footsub {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin-top: 45px;
		align-items: center;

		a {
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 16px;
			color: #fff;
			text-decoration: none;
			margin: 0 10px;
		}
	}

	.foot_info {
		text-align: center;

		h3 {
			margin-bottom: 10px;

			span {
				display: inline-block;
				margin-left: 5px;
			}
		}
	}
}
