.top-bar {
  display: flex;
  padding: 4px;
  align-items: center;
  gap: 12px;
  box-shadow: 0 0 16px 0 rgba(17, 17, 17, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: #fff;
  z-index: 1;
  color: $c-black;
  max-width: 760px;
  margin: 0 auto;
  &__left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1 0 auto;
  }

  &__icon {
    display: flex;
    width: 48px;
    height: 48px;
    padding: 4px;
    justify-content: center;
    align-items: center;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    svg {
      width: 24px;
      height: 24px;
      fill: #8d191d;
    }
  }
}
