.tab-bar {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.12);
  background: #fff;
  z-index: 99999;
  max-width: 760px;
  margin: 0 auto;

  &__list {
    display: flex;
    padding: 6px 0;
  }

  &__item {
    flex: 1 0 0;

    button {
      font-family:
        Tilda Sans,
        sans-serif;
      border: none;
      background: none;
      width: 100%;
      padding: unset;
      cursor: pointer;
    }
  }

  &__link {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    color: #808080;
    position: relative;

    &.active {
      color: $c_red;

      .tab-bar__img {
        svg {
          fill: #8d191d;
        }
        &-home {
          path {
            d: path(
              'M6 21C5.45 21 4.97917 20.8042 4.5875 20.4125C4.19583 20.0208 4 19.55 4 19V10C4 9.68333 4.07083 9.38333 4.2125 9.1C4.35417 8.81667 4.55 8.58333 4.8 8.4L10.8 3.9C10.9833 3.76667 11.175 3.66667 11.375 3.6C11.575 3.53333 11.7833 3.5 12 3.5C12.2167 3.5 12.425 3.53333 12.625 3.6C12.825 3.66667 13.0167 3.76667 13.2 3.9L19.2 8.4C19.45 8.58333 19.6458 8.81667 19.7875 9.1C19.9292 9.38333 20 9.68333 20 10V19C20 19.55 19.8042 20.0208 19.4125 20.4125C19.0208 20.8042 18.55 21 18 21H14V14H10V21H6Z'
            );
          }
        }
        &-basket {
          path {
            d: path(
              'M4.99935 21C4.71602 21 4.45769 20.9125 4.22435 20.7375C3.99102 20.5625 3.83269 20.3333 3.74935 20.05L0.949353 9.95C0.86602 9.71667 0.90352 9.5 1.06185 9.3C1.22019 9.1 1.43269 9 1.69935 9H6.74935L11.1494 2.45C11.2327 2.31667 11.3494 2.20833 11.4994 2.125C11.6494 2.04167 11.8077 2 11.9744 2C12.141 2 12.2994 2.04167 12.4494 2.125C12.5994 2.20833 12.716 2.31667 12.7994 2.45L17.1994 9H22.2994C22.566 9 22.7785 9.1 22.9369 9.3C23.0952 9.5 23.1327 9.71667 23.0494 9.95L20.2494 20.05C20.166 20.3333 20.0077 20.5625 19.7744 20.7375C19.541 20.9125 19.2827 21 18.9994 21H4.99935ZM11.9994 17C12.5494 17 13.0202 16.8042 13.4119 16.4125C13.8035 16.0208 13.9994 15.55 13.9994 15C13.9994 14.45 13.8035 13.9792 13.4119 13.5875C13.0202 13.1958 12.5494 13 11.9994 13C11.4494 13 10.9785 13.1958 10.5869 13.5875C10.1952 13.9792 9.99935 14.45 9.99935 15C9.99935 15.55 10.1952 16.0208 10.5869 16.4125C10.9785 16.8042 11.4494 17 11.9994 17ZM9.17435 9H14.7994L11.9744 4.8L9.17435 9Z'
            );
          }
        }
        &-profile {
          path {
            d: path(
              'M12 12C10.9 12 9.95833 11.6083 9.175 10.825C8.39167 10.0417 8 9.1 8 8C8 6.9 8.39167 5.95833 9.175 5.175C9.95833 4.39167 10.9 4 12 4C13.1 4 14.0417 4.39167 14.825 5.175C15.6083 5.95833 16 6.9 16 8C16 9.1 15.6083 10.0417 14.825 10.825C14.0417 11.6083 13.1 12 12 12ZM6 20C5.45 20 4.97917 19.8042 4.5875 19.4125C4.19583 19.0208 4 18.55 4 18V17.2C4 16.6333 4.14583 16.1125 4.4375 15.6375C4.72917 15.1625 5.11667 14.8 5.6 14.55C6.63333 14.0333 7.68333 13.6458 8.75 13.3875C9.81667 13.1292 10.9 13 12 13C13.1 13 14.1833 13.1292 15.25 13.3875C16.3167 13.6458 17.3667 14.0333 18.4 14.55C18.8833 14.8 19.2708 15.1625 19.5625 15.6375C19.8542 16.1125 20 16.6333 20 17.2V18C20 18.55 19.8042 19.0208 19.4125 19.4125C19.0208 19.8042 18.55 20 18 20H6Z'
            );
          }
        }
      }
    }

    &.value {
      .tab-bar__img-value {
        display: flex;
        padding: 4px;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: -3px;
        right: -17.5px;

        border-radius: 12px;
        border: #fff;
        background: #fdcb3d;

        color: $c-black;
        text-align: center;
        font-size: 10px;
        font-weight: 600;
        line-height: 10px;
        white-space: nowrap;
      }
    }
  }

  &__img {
    width: 24px;
    height: 24px;
    position: relative;

    svg {
      fill: #808080;
    }

    &-value {
      display: none;
    }
  }
}
.tabcart_disable {
  position: relative;
  cursor: no-drop !important;
  svg,
  span,
  a {
    opacity: 0.4;
  }
}

.coming_soon {
  position: absolute;
  opacity: 1;
  top: -2px;
  right: -20px;
  border: 1px solid var(--black-to-white-white-100);
  border-radius: 12px;
  padding: 4px;
  text-align: center;
  width: 40px;
  height: 20px;
  background: var(--yellow-yellow);
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 10px;
  line-height: 100%;
  text-align: center;
  color: var(--black-to-white-black);
}
