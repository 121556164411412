$c-black: #111;
$c-red: #8d191d;
$c-gray: #999;

:root {
  --deep-blue: #132a3e;
  --background: rgba(19, 42, 62, 0.5);
  --red-red: #8d191d;
  --red-red-hover: #731418;
  --red-red-click: #591012;
  --red-red-disabled: #dd989b;
  --red-red-field: #fae1e2;
  --yellow-yellow: #fdcb3d;
  --yellow-yellow-hover: #e3b637;
  --yellow-yellow-click: #c9a231;
  --yellow-yellow-disabled: #ffebb2;
  --yellow-yellow-field: #faf3e1;
  --green-green: #558950;
  --green-green-hover: #467042;
  --green-green-click: #365733;
  --green-green-disabled: #bfd6bc;
  --green-green-field: #e3fae1;
  --black-to-white-black: #111;
  --black-to-white-white-20-second-button-active: #333;
  --black-to-white-white-30: #4d4d4d;
  --black-to-white-white-40: #666;
  --black-to-white-white-50: #808080;
  --black-to-white-white-60: #999;
  --black-to-white-white-70: #b3b3b3;
  --black-to-white-white-75-black-disabled: #bfbfbf;
  --black-to-white-white-80: #ccc;
  --black-to-white-white-85: #d9d9d9;
  --black-to-white-white-90-white-50-disabled: #e6e6e6;
  --black-to-white-white-95: #f2f2f2;
  --black-to-white-white-100: #fff;
  --font-family: 'Tilda Sans VF', sans-serif;
  --second-family: 'Inter', sans-serif;
}
